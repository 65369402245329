import { EventSumInfo } from "@api/types/backendTypes";
import { getNearestAxisValue } from "@components/ChannelControlMetrics/ConversionValueChart";
import {
  comparedAtom,
  dashboardModeAtom,
  endTimeAtom,
  startTimeAtom,
} from "atoms";
import {
  chartTooltipStyles,
  CONSTANT_LABELS,
  GENERAL_CONSTANT_LABELS,
} from "constants/constants";
import { MarketingChannelOverviewInterface } from "interface/MarketingChannelOverviewInterface";
import { useAtom } from "jotai";
import React, { useEffect, useMemo, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useCurrency from "../../../lib/hooks/use-currency";
import dayjs from "dayjs";

type Props = {
  dailyChannelData: { [key: string]: MarketingChannelOverviewInterface[] };
  dailyStoreEventsData?: { [date: string]: EventSumInfo };
  dailyComparedChannelData?: {
    [date: string]: MarketingChannelOverviewInterface[];
  };
  dailyComparedStoreEventsData?: { [date: string]: EventSumInfo };
  title: string;
};

const getChartData = ({
  dailyChannelData,
  dailyStoreEventsData,
  comparedTimerange,
}: {
  dailyChannelData: Props["dailyChannelData"];
  dailyStoreEventsData?: Props["dailyStoreEventsData"];
  comparedTimerange?: number;
}) => {
  const currentChartData: { [key: string]: any }[] = [];
  const currentDataKeys: string[] = [];
  const currentColors: { [key: string]: string } = {};
  let currentMaxValue = 0;
  Object.entries(dailyChannelData).map(([date, values]) => {
    const valueData: { [key: string]: any } = {};
    if (comparedTimerange) {
      valueData["date"] = dayjs(date)
        .add(comparedTimerange, "day")
        .format("YYYY-MM-DD");
    } else {
      valueData["date"] = date;
    }
    let totalPurchaseAmount = 0;
    const storeEventsData = dailyStoreEventsData
      ? dailyStoreEventsData[date]
      : null;
    // if we have events data, use it here
    if (storeEventsData) {
      totalPurchaseAmount += storeEventsData.total_amount;
    }

    Object.values(values)?.forEach((el) => {
      if (!currentDataKeys.includes(el.channel.toLowerCase()) && el.spend > 0) {
        currentDataKeys.push(el.channel.toLowerCase());
      }
      // if we don't have events data, calculate it via the channel data
      // (probably never the case, except the event tracking fails)
      if (!storeEventsData) {
        totalPurchaseAmount += el.purchaseAmount;
      }
      if (currentDataKeys.includes(el.channel?.toLowerCase())) {
        valueData[el.channel.toLowerCase()] = el.spend.toFixed(2);
      }
      if (el.spend > currentMaxValue) {
        currentMaxValue = el.spend;
      }
      currentColors[el.channel.toLowerCase()] = el.color;
    });
    valueData["purchaseAmount"] = totalPurchaseAmount.toFixed(2);
    if (totalPurchaseAmount > currentMaxValue)
      currentMaxValue = totalPurchaseAmount;
    currentChartData.push(valueData);
  });
  for (const entry of currentChartData) {
    for (const key of currentDataKeys) {
      if (!entry[key]) entry[key] = 0;
    }
  }
  return { currentChartData, currentDataKeys, currentColors, currentMaxValue };
};
/**
 * Component holding the revenue chart info.
 *
 * @param {Props} props
 * @return {CustomElement}
 */
export function RevenueChart({
  dailyChannelData,
  dailyStoreEventsData,
  dailyComparedChannelData,
  dailyComparedStoreEventsData,
  title,
}: Props) {
  const [dataKeys, setDataKeys] = useState<Array<string>>([]);
  const [chartData, setChartData] = useState<{ [key: string]: any }[]>([]);
  const [colors, setColors] = useState<{ [key: string]: string }>({});
  const [maxValue, setMaxValue] = useState(0);
  const [dashboardMode] = useAtom(dashboardModeAtom);
  const compared = Boolean(Object.keys(dailyComparedChannelData ?? {}).length);
  const maxDateFromDaily = useMemo(
    () =>
      Math.max(...Object.keys(dailyChannelData).map((el) => dayjs(el).unix())),
    [dailyChannelData]
  );
  const minDateFromDaily = useMemo(
    () =>
      Math.min(...Object.keys(dailyChannelData).map((el) => dayjs(el).unix())),
    [dailyChannelData]
  );
  const timerange = useMemo(
    () =>
      dayjs(maxDateFromDaily * 1000).diff(
        dayjs(minDateFromDaily * 1000),
        "day"
      ) + 1, // we do + 1 because start is at 00:00 and end at 23:59 which is 1 day
    [maxDateFromDaily, minDateFromDaily]
  );

  useEffect(() => {
    setChartData([]);
    setDataKeys([]);
    setColors({});
    setMaxValue(0);
    if (Object.values(dailyChannelData).length > 0) {
      const chartData = getChartData({
        dailyChannelData,
        dailyStoreEventsData,
      });

      const {
        currentChartData,
        currentColors,
        currentDataKeys,
        currentMaxValue,
      } = chartData;

      let comparedChartData: typeof chartData | undefined;
      if (dailyComparedChannelData && timerange) {
        comparedChartData = getChartData({
          dailyChannelData: dailyComparedChannelData,
          dailyStoreEventsData: dailyComparedStoreEventsData,
          comparedTimerange: timerange,
        });
        for (const key of comparedChartData.currentDataKeys) {
          if (!currentDataKeys.includes(key)) {
            currentDataKeys.push(key);
          }
        }
        for (const value of comparedChartData.currentChartData) {
          const indexInCurrent = currentChartData.findIndex(
            (el) => el.date === value.date
          );
          if (indexInCurrent !== -1) {
            for (const key of currentDataKeys) {
              currentChartData[indexInCurrent][`compared_${key}`] = value[key];
            }
            currentChartData[indexInCurrent]["compared_purchaseAmount"] =
              value["purchaseAmount"] ?? 0;
          }
        }
      }
      setChartData(currentChartData);
      setDataKeys(currentDataKeys);
      setColors(currentColors);
      setMaxValue(
        comparedChartData?.currentMaxValue
          ? Math.max(currentMaxValue, comparedChartData.currentMaxValue)
          : currentMaxValue
      );
    }
  }, [
    dailyChannelData,
    dailyComparedChannelData,
    dailyComparedStoreEventsData,
    dailyStoreEventsData,
    timerange,
  ]);
  const maxYDomain = getNearestAxisValue(maxValue);
  const [xAxisTicks, setXAxisTicks] = useState(Object.keys(dailyChannelData));
  const { calculateCurrencyConvertion, globalCurrencySymbol } = useCurrency();
  useEffect(() => {
    function setXAxisTicksHandler() {
      const revenueChart = document.getElementById("revenue-chart-container");
      const width = revenueChart?.clientWidth ?? 0;
      const maxAmountOfTicks = Math.floor((width - 300) / (36 + 15)); // the labels are roughly 36px wide + a 15px spacing
      const dateKeys = Object.keys(dailyChannelData).length;
      const keysIndexToChoose = Math.ceil(dateKeys / maxAmountOfTicks);
      const keysToDisplay = Object.keys(dailyChannelData)
        .map((el, index) => (index % keysIndexToChoose === 0 ? el : null))
        .filter((el) => Boolean(el)) as string[];
      setXAxisTicks(keysToDisplay);
    }
    setXAxisTicksHandler();
    window.addEventListener("resize", setXAxisTicksHandler);
    return () => {
      window.removeEventListener("resize", setXAxisTicksHandler);
    };
  }, [dailyChannelData]);

  return (
    <ResponsiveContainer
      width="100%"
      height={350}
      id={"revenue-chart-container"}
    >
      <ComposedChart data={chartData}>
        <defs>
          {Object.entries(colors).map(([channel, color], index) => (
            <linearGradient
              id={`color-${channel}`}
              key={`color-${channel}-${index}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="5%" stopColor={color} stopOpacity={1} />
              <stop offset="95%" stopColor={color} stopOpacity={1} />
            </linearGradient>
          ))}
        </defs>

        <YAxis
          tickFormatter={(value: string) =>
            `${calculateCurrencyConvertion(
              parseFloat(value.toString() ?? "0"),
              0,
              false
            )}`
          }
          tickSize={0}
          axisLine={false}
          tickMargin={10}
          tickCount={6}
          width={100}
          ticks={[1, 2, 3, 4, 5].map(
            (el) => Math.round((maxYDomain / 5) * el * 100) / 100
          )}
          domain={[0, maxYDomain]}
        >
          <Label
            value={`${title} in ${globalCurrencySymbol}`}
            offset={20}
            angle={-90}
            position={{
              x: 20,
              y: 40,
            }}
            textAnchor="middle"
          />
        </YAxis>

        <XAxis
          dataKey="date"
          tickSize={0}
          tickMargin={20}
          axisLine={false}
          tickFormatter={(value: string) =>
            value ? String(value)?.split("-").slice(1).reverse().join("/") : ""
          }
          ticks={xAxisTicks}
        />
        <CartesianGrid stroke="var(--chart-grid)" vertical={false} />
        <Tooltip
          contentStyle={chartTooltipStyles}
          formatter={(value: string, name: string) => {
            let prefix = "";
            if (name.includes("compared_")) {
              prefix = "Previous ";
              name = name.replace("compared_", "");
            }
            return [
              `${calculateCurrencyConvertion(parseFloat(value), 2)}`,
              prefix +
                (dashboardMode === "general"
                  ? GENERAL_CONSTANT_LABELS[name]
                  : CONSTANT_LABELS[name]),
            ];
          }}
        />

        {dataKeys &&
          dataKeys.map((key, index) =>
            key === "purchaseAmount" ||
            key === "compared_purchaseAmount" ? null : (
              <>
                <Area
                  type="monotone"
                  dataKey={key}
                  key={key + index}
                  stackId={"spent"}
                  stroke={colors[key]}
                  fillOpacity={compared ? 0.1 : 1}
                  fill={`url(#color-${key})`}
                />
                {compared ? (
                  <Area
                    type="monotone"
                    dataKey={`compared_${key}`}
                    key={`compared_${key}` + index}
                    stackId={"compared_spent"}
                    stroke={colors[key]}
                    fillOpacity={0}
                    strokeDasharray={"5 5"}
                  />
                ) : null}
              </>
            )
          )}
        <Line
          type="monotone"
          strokeWidth={2}
          dataKey="purchaseAmount"
          key="purchaseAmount"
          stroke="#3EA142"
          dot={false}
        />
        {compared ? (
          <Line
            type="monotone"
            strokeWidth={2}
            dataKey="compared_purchaseAmount"
            key="compared_purchaseAmount"
            stroke="#3EA142"
            dot={false}
            strokeDasharray={"5 5"}
          />
        ) : null}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
